<template>
  <div class="page-admin">
    <div class="page-admin__header">
      <Header />
    </div>
    <div class="page-admin__sidebar">
      <SideNav />
    </div>
    <div
      class="page-admin__main load-container"
      :class="{
        'load-container-active': isLoading
      }"
    >
      <router-view />

      <div class="load-container--loading" >
        <!-- https://github.com/ankurk91/vue-loading-overlay -->
        <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" :width="128" :height="128" stroke="var(--color-prime)">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".25" cx="18" cy="18" r="18"/>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.8s"
                  repeatCount="indefinite"/>
              </path>
            </g>
          </g>
        </svg>
      </div>

    </div>
  </div>
</template>

<script>
import Header from "@component/Part/Header";
import SideNav from "@component/Part/SideNav";

export default {
  components: {
    Header,
    SideNav,
  },
  computed: {
    isLoading() {
      return this.$store.getters["loading/isLoading"];
    },
  },
};
</script>

<style lang="scss" scoped>

// page-admin
.page-admin {
  position: relative;
  padding: 52px 32px 42px 292px;
  .page-admin__header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: var(--bg-prime);
    z-index: 10;
  }
  .page-admin__sidebar {
    position: absolute;
    left: 0;
    top: 0;
    width: 260px;
    height: 100%;
    min-height: 100vh;
    padding-top: 52px;
    background-color: var(--bg-prime);
    z-index: 5;
  }
  .page-admin__main {
    position: relative;
    width: 100%;
    max-width: 1152px;
    margin: 0 auto;
    padding: 24px 10px;
    background-color: var(--bg-sub);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.55);
    border-radius: 12px;
  }
}

.load-container {
  .load-container--loading {
    display: none;
  }

  &.load-container-active {
    overflow-y: hidden;
    position: relative;

    .load-container--loading {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      z-index: 10;

      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      background: rgba(76, 76, 76, 0.3);
    }

  }
}

</style>

<template>
  <header class="header">
    <router-link :to="{ name: this.$routeName.PROFILE }" class="header__user">
      <div class="header__user-ava">
        <template v-if="user.avatar">
          <img :src="user.avatar" alt="">
        </template>
        <template v-else>
          <svg width="70" height="57" viewBox="0 0 70 57" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header__user-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.907 2.48a2.25 2.25 0 011.636-.705h16.354a2.25 2.25 0 011.663.735l5.852 6.423a3.75 3.75 0 002.773 1.225h12.534a2.25 2.25 0 012.25 2.25v40.41a2.25 2.25 0 01-2.25 2.25H4.28a2.25 2.25 0 01-2.25-2.25v-40.41a2.25 2.25 0 012.25-2.25h11.755a3.75 3.75 0 002.725-1.175l6.146-6.503zM26.543.275a3.75 3.75 0 00-2.726 1.174l-6.146 6.504a2.25 2.25 0 01-1.635.704H4.28a3.75 3.75 0 00-3.75 3.75v40.41a3.75 3.75 0 003.75 3.75H65.72a3.75 3.75 0 003.75-3.75v-40.41a3.75 3.75 0 00-3.75-3.75H53.185a2.25 2.25 0 01-1.664-.734L45.67 1.499A3.75 3.75 0 0042.897.275H26.543zm.63 29.542a7.548 7.548 0 1115.096 0 7.548 7.548 0 01-15.096 0zm7.548-9.047a9.048 9.048 0 100 18.095 9.048 9.048 0 000-18.095z" fill="#DEE4ED"/></svg>
        </template>
      </div>
      <div class="header__user-name">{{ user.first_name }}</div>
    </router-link>
    <div class="header__logout" @click="logout">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.367 6.656a7.5 7.5 0 107.383.066l.75-1.3a9 9 0 11-8.862-.079l.729 1.313z" fill="#ACACAC"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 12V1.5h1.5V12h-1.5z" fill="#ACACAC"/></svg>
    </div>
  </header>
</template>

<script>

export default {
  name: "Header",
  data() {
    return {
      user: {},
    };
  },
  methods: {
    async logout() {
      await this.$store.commit('user/setToken', '');
      this.$nextTick(() => {this.$router.push({name: this.$routeName.AUTH}); });
    }
  },
  mounted() {
    RequestManager.Admin.getProfileMe({}).then((data) => {
      this.user = Object.assign({}, data);
    });
  }
};
</script>

<style lang="scss" scoped>

// header
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 52px;
  padding: 0 22px;
  .header__user {
    display: flex;
    align-items: center;
    .header__user-ava {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      margin-right: 10px;
      border-radius: 50%;
      background-color: var(--bg-sub);
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .header__user-icon {
        width: 14px;
        height: auto;
      }
    }
    .header__user-name {
      color: var(--text-sub);
      font-size: 17px;
      transition: all .2s ease-in-out;
    }
    &:hover {
      .header__user-name {
        color: var(--color-prime);
      }
    }
  }
  .header__logout {
    cursor: pointer;
    svg {
      path {
        fill: var(--text-sub);
        transition: all .2s ease-in-out;
      }
    }
    &:hover {
      svg {
        path {
          fill: var(--color-prime);
        }
      }
    }
  }
}

</style>
